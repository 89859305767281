'use strict'
// import '@babel/polyfill'
import SmoothScroll from './_SmoothScroll.js'
// import ResizeHelper from './_ResizeHelper.js'
// import ScrollHelper from './_ScrollHelper.js'
import Intersection from './_Intersection.js'
import ScrollDirectionClass from './_ScrollDirectionClass.js'
import Slider from './_Slider.js'
import Sticky from './_Sticky.js'

const BREAK_POINT = 1024




$(() => {
  const $window = $(window)
  const $body = document.querySelector('body')
  $body.classList.add('loaded')

  if(document.getElementById('js-index-slider')) {
    new Slider('js-index-slider')
  }


  const smoothScroll = new SmoothScroll({
    breakpoint: BREAK_POINT,
    offset: {
      pc: document.getElementById('header').getBoundingClientRect().height + 80,
      sp: (document.getElementById('header').getBoundingClientRect().height + (80 / window.innerWidth * 100)) * window.devicePixelRatio
    }
  })
  smoothScroll.begin = () => {
    if (window.innerWidth < BREAK_POINT) {
      $body.classList.remove('menu-active')
    }
  }
  
  // sp menu
  $('.js-toggle-menu').on('click', e => {
    $body.classList.toggle('is-menu-active')
  })
  $('.js-toggle-next').on('click', e => {
    $(e.currentTarget).toggleClass('is-active').next().slideToggle()
  })


  // toasts: service/consulting
  $('.js-handle-toasts').each((index, el) => {
    const $wrapper = $(el);
    $wrapper.find('.js-handle-toasts--open').on('click', e => {
      $wrapper.addClass('is-open')
    })
    $wrapper.find('.js-handle-toasts--close').on('click', e => {
      $wrapper.removeClass('is-open')
    })
  })
  

  // header inquiry background
  $('.js-inquiry-overlay').on('mouseenter', e => {
    $body.classList.add('inquiry-active')
    e.stopPropagation()
  })
  $('.js-inquiry-overlay').on('mouseleave', e => {
    $body.classList.remove('inquiry-active')
    e.stopPropagation()
  })



  // // active menu
  // $('.js-active-menu a').each((index, el) => {
  //   const link = new URL(el.getAttribute('href'))
  //   console.log(link)
  // })



  new Intersection('.l-iceblock', entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('is-inview')
      // } else {
      //   document.body.classList.remove('is_inview')
      }
    })
  }, {
    root: null,
    rootMargin: "-40% 0px -10% 20px",
    threshold: 0
  })



  // class ScrollDirection {
  //   constructor() {
  //     this.prev = 0
  //     new ScrollHelper(e => {
  //       $body.classList.toggle('scroll-up', this.prev > window.pageYOffset)
  //       this.prev = window.pageYOffset
  //     }, 1000 / 5)
  //   }
  // }

  // sticky header
  new Sticky('header');
  // sp footer
  new ScrollDirectionClass();
})
