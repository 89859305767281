// libNN.ScrollHelper.js 2023/01
// add throttle

let requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
let running = false;
const FRAME_IN_MS = 1000 / 30;

import { throttle } from 'throttle-debounce'

class ScrollHelper {
  constructor(callback, frameRate) {
    this.frameRate = frameRate
    this.callback = callback;
    this.listener = this.process.bind(this);
    window.addEventListener('scroll', throttle(this.frameRate, this.listener));
  }
  process() {
    const callback = this.callback;
    if (!running) {
      running = true;
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(callback);
      } else {
        setTimeout(callback, this.frameRate);
      }
      running = false;
    }
  }
  destroy() {
    window.removeEventListener('scroll', this.listener);
  }
}

export default ScrollHelper;
