
import ResizeHelper from './_ResizeHelper.js'
import ScrollHelper from './_ScrollHelper.js'

class Sticky {
  constructor(el, target) {
    const $el = document.getElementById(el)
    const resizeHelper = new ResizeHelper(() => {
      this.windowHeight = window.innerHeight
      this.targetHeight = $el.getBoundingClientRect().height
      this.targetOffset = $el.offsetTop
    }, 1000 / 10)
    resizeHelper.init()

    new ScrollHelper(e => {
      $el.classList.toggle('sticky', window.pageYOffset >= this.targetHeight)
      document.body.classList.toggle('sticky', window.pageYOffset >= this.targetHeight)
    }, 1000 / 30)
  }
}

export default Sticky;
