import Timer from './_Timer.js'

function zeropadding(i, length) {
  return ('00000' + i).slice(length * -1)
}

class Slider {
  constructor(el, time, callback) {
    this.index = 0
    this.el = document.getElementById(el)
    this.speed = this.el.getAttribute('data-slide-speed') || 1000
    this.slide_length = this.el.querySelectorAll('.js-slider-item').length
    this.playbutton = this.el.querySelector('.js-slider-control')
    this.currentEl = this.el.querySelector('.js-slider-current')
    this.totalEl = this.el.querySelector('.js-slider-total')
    this.nextEl = this.el.querySelector('.js-slider-next')
    this.prevEl = this.el.querySelector('.js-slider-prev')
    this.totalEl.innerText = zeropadding(this.slide_length, 2)
    this.bind()
    this.start()
  }
  bind() {
    this.playbutton.addEventListener('click', e => {
      this.toggle()
    })
    this.nextEl.addEventListener('click', e => {
      this.next()
    })
    this.prevEl.addEventListener('click', e => {
      this.prev()
    })
  }


  start() {
    this.el.setAttribute('data-active', true)
    this.timer = new Timer(e => {
      this.index = (this.index + 1) % this.slide_length
      this.update()
    }, this.speed )
  }
  pause() {
    this.timer.clear()
    this.el.setAttribute('data-active', false)
  }


  prev() {
    this.timer.clear()
    this.index = this.index === 0 ? this.slide_length - 1 : (this.index - 1) % this.slide_length
    this.update()
    this.timer.start()
  }
  next() {
    this.timer.clear()
    this.index = (this.index + 1) % this.slide_length
    this.update()
    this.timer.start()
  }
  toggle() {
    // this.timer.isPlay ? this.pause() : this.start()
    if( this.timer.isPlay ) {
      this.pause()
    } else {
      this.start()
      this.next()
    }
  }
  update() {
    this.currentEl.innerText = zeropadding(this.index + 1, 2)
    this.el.setAttribute('data-slide-count', this.index)
  }
  
}
export default Slider;
