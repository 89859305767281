
import ScrollHelper from './_ScrollHelper.js'

class ScrollDirectionClass {
  constructor() {
    this.prev = 0
    new ScrollHelper(e => {
      document.querySelector('body').classList.toggle('scroll-up', this.prev > window.pageYOffset)
      this.prev = window.pageYOffset
    }, 1000 / 5)
  }
}

export default ScrollDirectionClass;
