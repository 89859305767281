
class Timer {
  constructor(callback, time) {
    this.callback = callback
    this.time = time || 1000
    this.timer = null
    this.isPlay = false
    this.start()
  }
  start() {
    if( this.timer ) return
    this.isPlay = true
    this.timer = setInterval(this.callback, this.time)
  }
  clear() {
    this.isPlay = false
    this.timer = clearInterval(this.timer)
  }
}
export default Timer;
