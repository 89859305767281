'use strict'

// import 'url-polyfill'
// import smoothscroll from "smoothscroll-polyfill"

// smoothscroll.polyfill()

const BREAK_POINT = 750
const DESIGN_VIEWPORT = 750
const INVIEW_OFFSET = 30

function vw(px){
  const _vw = (px/DESIGN_VIEWPORT) * window.innerWidth
  return _vw
}
class SmoothScroll{
  constructor(setting_arg){
    const config = {
      setonload: true,
      breakpoint: BREAK_POINT,
      offset: {
        pc: 0,
        sp: 0
      }
    }
    this.config = (Object.assign(config, setting_arg))
    this.callback_list = []

    this.bindEvents()
    return

  }
  bindEvents(){
    const hashList = document.querySelectorAll('a[href *= "#"]')
    const innerLinks = this.filter_otherPage(hashList)

    innerLinks.forEach(anchor => {
      anchor.addEventListener('click', this.onClick.bind(this))
    })

    if(this.config.setonload && location.hash !== ''){
      this.onload()
    }
  }
  filter_otherPage(hashlist){
    return Array.from(hashlist).filter((el, i, ary) => {
    const href = el.getAttribute('href')

    const url = new URL(href, location)
    const url_from = location.origin + location.pathname
    const url_to = url.origin + url.pathname

    return url_from === url_to
    })
  }
  onClick(e){
    const href = e.currentTarget.getAttribute('href')
    const hash = '#' + href.split('#')[1]
    const targetElement = document.querySelector(hash)
    const targetPosition = this.calcPosition(targetElement)

    this.startScroll(targetPosition)

    e.preventDefault()
    e.stopPropagation()
  }
  calcOffset(){
    const is_sp = window.innerWidth < this.config.breakpoint
    return is_sp ? vw(this.config.offset.sp) : this.config.offset.pc
  }

  calcPosition(targetElement){
    if(targetElement) targetElement.classList.add('scrolltarget')
    const translated = targetElement && targetElement.classList.contains('inview') && !targetElement.classList.contains('is-inview') ? INVIEW_OFFSET : 0
    const offset = this.calcOffset() + translated
    var to = targetElement ? targetElement.getBoundingClientRect().top + window.pageYOffset - offset  : 0
    return to
  }

  startScroll(to, speed){
    this.begin()
    window.scrollTo({
      top: to,
      behavior: "smooth"
    })
  }
  scrollImmediate(to){
    this.begin()
    window.scrollTo({
      top: to,
    })
  }
  onload(){
    const images = document.querySelectorAll('img')
    let loadedImages = 0
    window.addEventListener('load', () => {
      setTimeout(() => {
        const target_to_scroll = location.hash.indexOf('#/') !== 0 ? document.querySelector(location.hash) : null
        if(target_to_scroll){
          const targetPosition = this.calcPosition(target_to_scroll)
            this.scrollImmediate(targetPosition)
        }
      }, 10)
    })
  }

  // calback placeholder
  begin(){
    return null
  }
  complete(){
    return null
  }

}

export default SmoothScroll
